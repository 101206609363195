<template>
    <el-dialog :visible.sync="dialogVisible" title="添加产品信息" width="80%" :close-on-click-modal="false" :close-on-press-escape="false">
        <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="200px">
            <el-form-item label="产品名称" prop="name">
                <el-input type="text" v-model.trim="ruleForm.name" placeholder="请输入产品名称(2-10个字符)"></el-input>
            </el-form-item>
            <el-form-item label="简介" prop="introduction">
                <el-input type="text" v-model.trim="ruleForm.introduction" maxlength="50" show-word-limit placeholder="请输入简介(0-50个字符)"></el-input>
            </el-form-item>
            <el-form-item label="封面图(仅支持png格式图片。建议分辨率260*260,大小不超过500k)" prop="image_url">
                <el-upload
                        class="products-uploader"
                        :with-credentials="true"
                        :action="action"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload">
                    <img v-if="imageUrl" :src="imageUrl" class="products">
                    <i v-else class="el-icon-plus banners-uploader-icon"></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="所属类别" prop="reference">
                <el-select v-model="ruleForm.reference" placeholder="请选择类别">
                    <el-option v-for="(item,index) in productTypes" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="内容" prop="content">
                <vue-tinymce v-model="ruleForm.content" :setting="setting" />
            </el-form-item>
        </el-form>
        <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submit('ruleForm')" v-loading.fullscreen.lock="fullscreenLoading">确 定</el-button>
                </span>
        </template>
    </el-dialog>
</template>

<script>
    export default {
        name: "Dialog",
        props:{
            productTypes:{
                type:Array,
            },
        },
        data(){
            return{
                dialogVisible:false,    //显示本组件
                fullscreenLoading: false,//loading框
                action:'/api/carousel/test',      //图片上传地址
                imageUrl:'',    //
                ruleForm: {
                    name:'',
                    introduction:'',
                    image_url:'',
                    reference:'',
                    content:'',
                },
                image_list:[],  //tinymce中新增的图片
                rules: {
                    name: [
                        { required: true, message: '请输入产品名称', trigger: 'blur' },
                        { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
                    ],
                    introduction:[
                        { required: true, message: '请输入简介', trigger: 'blur' },
                        { min: 0, max: 50, message: '长度在 0 到 50 个字符', trigger: 'blur' }
                    ],
                    reference:[
                        { required: true, message: '请选择类别', trigger: 'blur' },
                    ],
                    image_url:[
                        { required: true, message: '请上传封面图', trigger: 'blur' },
                    ],
                    content:[
                        { required: false, message: '请输入产品内容', trigger: 'blur' },
                    ],
                },
                setting: {
                    menubar: false,
                    toolbar: "undo redo | fullscreen | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | image media table | fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |",
                    toolbar_drawer: "sliding",
                    quickbars_selection_toolbar: "removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor",
                    plugins: "link image media table lists fullscreen quickbars",
                    language: 'zh_CN',
                    height: 600,
                    // inline:true,
                    images_upload_handler:async (blobInfo,success,failure)=>{
                        let formData = new FormData();
                        formData.append('file',blobInfo.blob(),blobInfo.filename());
                        this.$axios({
                            method:'POST',
                            url:this.action,
                            data:formData,
                        }).then(res => {
                            this.image_list.push(res.data.file_name);
                            success(this.$store.getters.getTmpPicBaseUrl + res.data.file_name);
                        })
                            .catch(()=>{
                                failure('上传失败');
                            })
                    },
                }
            }
        },
        methods:{
            //打开本组件
            open(){
                this.dialogVisible = true;
            },
            //提交表单
            submit(formName) {
                this.$refs[formName].validate(async valid => {
                    if (valid) {
                        this.fullscreenLoading = true;
                        try{
                            if(this.image_list.length){
                                this.ruleForm.content = this.ruleForm.content.replaceAll("/tmp_resources/","/images/website/");
                            }

                            const {data} = await this.$axios({
                                method:'POST',
                                url:'/api/thirdtitle/add',
                                data:{
                                    name:this.ruleForm.name,
                                    introduction:this.ruleForm.introduction,
                                    status:0,
                                    reference:this.ruleForm.reference,
                                    content:this.ruleForm.content.replaceAll("'","\\'"),
                                    image_url:this.ruleForm.image_url,
                                    image_list:this.image_list,
                                    image_list_status:this.image_list.length>0?1:0,
                                }
                            });
                            this.fullscreenLoading = false;
                            if(data.code){
                                this.$message({
                                    message: data.msg,
                                    type: 'success',
                                });
                                this.imageUrl = '';
                                this.$emit('success');
                                this.dialogVisible = false;
                            }else{
                                this.$message.error(data.msg);
                            }
                        }catch (e) {
                            this.fullscreenLoading = false;
                            this.$message.error('网络错误，请稍后重试');
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            //上传成功执行
            handleAvatarSuccess(res, file) {
                this.imageUrl = URL.createObjectURL(file.raw);
                this.ruleForm.image_url = res.file_name;
            },
            //上传前校验
            beforeAvatarUpload(file) {
                const isPNG = file.type === 'image/png';
                const isLt500K = file.size / 1024 < 5120;

                if (!isPNG) {
                    this.$message.error('上传图片只能是 PNG 格式!');
                }
                if (!isLt500K) {
                    this.$message.error('上传图片大小不能超过 5 Mb!');
                }
                return (isPNG) && isLt500K;
            }
        },
        watch:{
            //清空表单
            dialogVisible(val){
                if(!val){
                    this.imageUrl = '';
                    this.image_list = [];
                    this.$refs['ruleForm'].resetFields();
                }
            }
        }
    }
</script>

<style>
    .products-uploader .el-upload--text{
        width: auto;
        height: auto;
    }
</style>

<style scoped>
    .tox .tox-toolbar, .tox .tox-toolbar__overflow, .tox .tox-toolbar__primary{
        background-position: 0 34px;
    }
</style>