<template>
    <div class="productAll">
        <div class="container">
            <div class="handle-box">
                <el-button type="primary" icon="el-icon-circle-plus-outline" class="handle-del mr10" @click="add">添加产品</el-button>
                <el-select v-model="typeId" filterable placeholder="请选择" @change="handleChange">
                    <el-option v-for="item in productTypes" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </div>
            <el-table v-loading="loading" border :data="tableData" :header-cell-style="{backgroundColor:'#f5f7fa'}">
                <el-table-column align="center" prop="name" label="产品名称"></el-table-column>
                <el-table-column align="center" prop="image_url" label="封面图">
                    <template slot-scope="scope">
                        <el-image style="width: 100%;" :src="$store.getters.getPicBaseUrl + scope.row.image_url" fit="contain" />
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="status" label="状态">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.status" active-text="已上架" inactive-text="已下架" :active-value="1" :inactive-value="0" active-color="#13ce66" inactive-color="#ff4949" @change="changeStatus(scope.row,scope.$index)"></el-switch>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="createtime" label="创建时间">
                    <template slot-scope="scope">{{scope.row.time.replace('T',' ')}}</template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button @click="handleEdit(scope.row)" icon="el-icon-edit" type="success" size="small">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination background layout="total, prev, pager, next" :current-page="page" :page-size="size" :total="total" @current-change="getList"></el-pagination>
            </div>
        </div>

        <Dialog ref="dialog" :productTypes="productTypes" @success="getList" />
        <editDialog ref="editDialog" :productTypes="productTypes" @success="getList" />
    </div>
</template>

<script>
    import Dialog from '@/components/ProductAll/dialog'
    import editDialog from '@/components/ProductAll/editDialog'
    export default {
        name: "ProductAll",
        components:{
            Dialog,
            editDialog,
        },
        data(){
            return{
                typeId:null,    //分类id
                productTypes:[],   //所有分类数组
                tableData:[],    //数据列表
                total:0,        //总条数
                page:1,         //当前页
                size:10,        //每页条数
                loading:false,   //loading框
            }
        },
        created(){
            this.getTypeList();
        },
        methods:{
            //获取产品类别数据
            async getTypeList(){
                try{
                    const {data} = await this.$axios({
                        method:'POST',
                        url:'/api/secondtitle',
                        data:{id:3},
                    });
                    if(data.code){
                        this.productTypes = data.SecondTitle_ret;
                        this.typeId = this.productTypes[0].id;
                        this.getList(1);
                    }
                }catch (e) {
                    //
                }
            },
            //获取列表数据
            async getList(page=1){
                this.page = page;
                this.loading = true;
                try{
                    const {data} = await this.$axios({
                        method:'POST',
                        url:`/api/thirdtitle/${page}/${this.size}`,
                        data:{
                            nid:this.typeId,
                        }
                    });
                    this.loading = false;
                    if(data.code){
                        this.tableData = data.data;
                        this.total = data.count_num;
                    }
                }catch (e) {
                    this.loading = false;
                    console.log(e);
                }
            },
            //添加菜单
            add(){
                this.$refs.dialog.open();
            },
            //点击修改状态
            async changeStatus(row,index){
                try{
                    const {data} = await this.$axios({
                        method:'POST',
                        url:'/api/thirdtitle/statusedit',
                        data:{
                            status:row.status,
                            nid:row.id,
                        },
                    });
                    if(data.code){
                        this.$message({
                            message: data.msg,
                            type: 'success',
                        });
                    }else{
                        this.$message.error(data.msg);
                        this.tableData[index].status = row.status?0:1;
                    }
                }catch (e) {
                    this.$message.error('网络错误，请稍后重试');
                    this.tableData[index].status = row.status?0:1;
                }
            },
            //修改菜单信息
            handleEdit(row){
                this.$refs.editDialog.open(row);
            },
            //下拉框改变触发
            handleChange(id){
                this.typeId = id;
                this.getList(1);
            },
        },
    }
</script>

<style scoped>
    .handle-box {
        margin-bottom: 20px;
    }
    .mr10{
        margin-right: 10px;
    }
</style>